module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lct-site","accessToken":"MC5ZSklpWmhFQUFDQUFLU3hV.He-_ve-_ve-_ve-_vSTvv70Ifu-_ve-_vRwB77-9PzTvv71k77-9CEXvv71aFFpt77-977-9V--_ve-_ve-_vQ","promptForAccessToken":true,"apiEndpoint":"https://lct-site.cdn.prismic.io/api/v2","lang":"*","imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-JBSTKPHTP9"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Lender Compliance Technologies","short_name":"LCT","start_url":"/","background_color":"#FFFFFF","theme_color":"#3A72B5","display":"minimal-ui","icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8933a57f9cd8c87ddfa1cb871f1e7786"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
